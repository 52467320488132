/*

USAGE:

<label class="c_radio" for="option_1">
  <input type="radio" name="" id="option_1">
  <span class="c_radio__mark"></span>
  <span>Radio</span>
</label>

<label class="c_checkbox" for="option_1">
  <input type="checkbox" name="" id="option_1">
  <span class="c_checkbox__mark"></span>
  <span>Checkbox</span>
</label>

*/

.c_checkbox,
.c_radio{
  display: flex;
  align-items: center;
  cursor: pointer;

  [type="checkbox"],
  [type="radio"]{
    display: none !important;
  }

  [type="checkbox"] + &__mark,
  [type="radio"] + &__mark{
    border: 1px solid #000;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    flex: none;
    margin-right: 1rem;
    &::after{
      background-color: #000;
      content: '';
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.6);
      transition: all .1s ease-out;
      opacity: 0;
    }

    @screen lg{
      width: 75px;
      height: 75px;

      &::after{
        width: 75px;
        height: 75px;
      }
    }
  }

  &__mark + span{}

  [type="checkbox"] + &__mark {
    border-radius: 0;
    &::after{
      border-radius: 0;
    }
  }

  [type="checkbox"]:checked + &__mark,
  [type="radio"]:checked + &__mark {
    &::after{
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}