

.intro{
  position: relative;
  .titles{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1{
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      margin-top: 6.75rem;
      font-size: 40px;
      @screen lg{
        margin-bottom: 1.25rem; 
      }
  
    }
    h2{
      margin-top: -1rem;
      text-align: center;
      font-size: 80px;
      font-weight: 400;
      text-transform: uppercase;
      @screen lg{
        font-weight: 300;
        margin-top: -3rem;
        font-size: 200px;
      }
    }
  }
  .wait{
    font-size: 70px;
    font-weight: 400;
    text-transform: uppercase;
    @screen lg{
      font-weight: 300;
      font-size: 200px;
    }
  }
  .description{
    margin-top: 3rem;
    font-size: 2rem;
    @screen lg{
      
      font-size: 56px;
    }
    &__second{
      margin-top: 0;
    }
  }
  .arrow{
    margin: 3rem 0;
  }
  .divider{
    width: 100%;
    background-color: black;
    height: 2px;
  }

  .content{
    display: flex;
    padding: 3rem 0;
    flex-direction: column;
    @screen lg{
      flex-direction: row;
    }
    &__left{
      width: 100%;
      margin-bottom: 2rem;
      @screen lg{
        margin-bottom: 0;
        width: 50%;
      }
      p{
        font-size: 1.75rem;
        @screen lg{
          font-size: 38px;
        }
        font-weight: 500;
      }
    }
    &__right{
      width: 100%;
      @screen lg{
        width: 50%;
      }
      span{
        font-size: 1.25rem;
        font-weight: 500;
      }
      &__list{
        margin-top: 1rem;
        margin-bottom: 2rem;
        li{
          display: flex;
          gap: 22px;
          font-size: 1.25rem;
          &::before{
            display: block;
            height: 23px;
            width: 23px;
            // margin-top: 5px;
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23' fill='none'%3E%3Cline y1='12' x2='23' y2='12' stroke='black' stroke-width='2'/%3E%3Cline x1='11' y1='4.37114e-08' x2='11' y2='23' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
          }

          .alt{
            display: flex;
            flex-direction: column;
          }
        }
        li + li{
          margin-top: 1rem;
        }
      }
      p{
        font-size: 1.25rem;
        span{
          margin-bottom: 1.25rem;
        }
        ul{
          li{
            font-size: 1.25rem;
            margin-left: 1rem;
            list-style: inside;
          }
        }
      }
    }
  }

}

.Scroll_Button{
  cursor: pointer;
  $root: &;
  position: absolute;
  top: 0;
  right: 10%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  @screen sm{
    width: 130px;
    height: 130px;
  }
  @screen lg{
    width: 200px;
    height: 200px;
  }
  &--alt{
    &::before{
      background: rgba(#fff, 0.2)
    }
  }

  &__Circle{
    animation-duration: 10s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &__Arrow{
    position: absolute;
    top: 50%;
    left: 50%;
    @screen lg{
      padding-right: 6px;
    }
    transform: translate(-50%, -50%);
    width: 35%;
    height: 35%;
    animation-duration: 1.5s;
    animation-name: move;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &__container{
    position: relative;
    

  }
}

.grid-images {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 1.25rem;
  margin-bottom: 3.5rem;

  @screen md {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @screen md {
    img:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  
    img:nth-child(2) {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  
    img:nth-child(3) {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg); 
  }

  to {
    transform: rotate(360deg); 
  }
}

@keyframes move {
  0% {
    transform: translate3d(-50%, -50%, 0); 
  }

  50% {
    transform: translate3d(-50%, -40%, 0); 
  }

  100%{

  }
}