

:root{
  --beige: #FFF8F2;
  --secondary: #FFF9D0;
  --alternative: #FFE312;

  --ui-color: #ffc408;
  --ui-alt-color: #ffc408;

  --custom-input-border: #ffc408;
  --custom-input-background: #ffc408;
}

*{
  box-sizing: border-box;
}

body{
  background-color: var(--beige, #000);
  color: #000;
  font-family: 'Helevetica';
}


section{
  padding: 0 1rem;
}

@media (min-width: 1024px){
  section{
    padding: 0 2rem;  
  }
}