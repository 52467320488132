

.booking{
  &__item{
    
    &__input{
      position: relative;
      display: flex;
      width: fit-content;
      align-items: center;
      padding: 0 10px;
      border-radius: 100px;
      border: 2px solid #000;
      @screen lg{
        padding: 0 2.625rem;
      }

      > div{
        display: flex;
        flex-direction: column;

        span{
          white-space: nowrap;
        }
        span + span{
          white-space: nowrap;
          font-size: 9px;

          @screen lg{
            font-size: 14px;
          }
        }
      }

      span{
        flex: none;
        margin-right: 6px;
        font-size: 14px;

        @screen lg{
          margin-right: 2rem;
          font-size: 24px;
        }
      }

      input{
        width: 100%;
        height: 100%;
        padding: 0.5rem 0.5rem;
        background-color: transparent;
        border: 0;
        font-size: 20px;
        // color: var(--ui-color, #fff);

        @screen lg{
          padding: 2rem 2rem;
          font-size: 56px;
        }

        &:focus{
          outline: none;
        }
      }

    

      &__container{
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        @screen lg{
          margin-top: 4rem;
        }
      }

      &--boolean{
        cursor: pointer;
       
        span.text{
          padding: 12px 0;
          font-size: 2rem;
          
          @screen lg{
            font-size: 75px;
            padding: 2rem 0;
          }
          @screen sm{
            font-size: 3rem;
          }
        }
      }

    }

    &__input_mail{
      margin-top: 4rem;
      padding: 44px 0;
      border-top: 2px solid #000;
      border-bottom: 2px solid #000;
      display: flex;
      align-items: center;
      span{
        margin-left: 2rem;
        font-size: 1rem;
        @screen lg{
          margin-left: 42px;
          font-size: 1.5rem;
          margin-top: .75rem;
        }
        text-transform: uppercase;
      }

      input{
        height: 100%;
        background-color: transparent;
        border: 0;
        margin-left: 1.5rem;
        @screen lg{
          margin-left: 75px;
          font-size: 56px;
        }
        &::placeholder{
          color: black;
          opacity: .5;
        }
        &:focus{
          outline: none;
        }
      }

    }

    &__input + &__input{

      margin-top: 2rem;
    }

    &__radio{
      // background-color: rgba($color: #fff, $alpha: 0.2);
      display: flex;
      align-items: center;
      gap: 10px;
      // padding: 5px 10px;
      border-radius: 100px;
      // border: 2px solid #000;
      cursor: pointer;
    }

    &__radio + &__radio{
      margin-left: 10px;
    }
    
  }
}