$base: './fonts/';


@font-face {
    font-family: 'Helevetica';
    src: url('#{$base}HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helevetica';
    src: url('#{$base}HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helevetica';
    src: url('#{$base}HelveticaNowDisplay-Light.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}