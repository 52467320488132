

.booking{
  &__title{
    font-weight: 300;
    line-height: 100%;
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    @screen sm{
      font-size: 4rem;
    }
    @screen lg{
      margin-top: 2.5rem;
      font-size: 200px;
      margin-bottom: 2.5rem;
    }
  }

  &__subtitle{
    font-size: 1.75rem;
    @screen sm{
      font-size: 2.5rem;
    }
    @screen lg{
      font-size: 56px;
    }
  }

  &__submit_button{
    border: 0;
    width: 100%;
    border-radius: 2rem;
    font-size: 200px;
    font-weight: 500;
    overflow: hidden;
    color: var(--blue);
    position: relative;
    
    margin-top: 3rem;
    padding: 0 3rem;
    @screen sm{
      
      margin-top: 4rem;
      padding: 0 5rem;
    }
    @screen lg{
      margin-top: 5.75rem;
      padding: 0 7.25rem;

    }

    &--notcoming{
      margin-top: 1rem;

      @screen lg{
        margin-top: 2.5rem;
      }
    
    }
  }
}