

.anim_intro{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 1s;
  animation-name: slidein;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-timing-function: ease-out;
  z-index: 9999;


  

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


@keyframes slidein {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -100%);
  }
}